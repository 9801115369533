<template>
  <div class="commandes">
    <div class="title_a">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" /></svg>
      Mes Commandes
    </div>
    
    
    <div class="commande" v-for="n in commandes" :key="n.id">
      <div class="top">
        <div class="c etat">
          {{ n.status }}
<!--          <span>En dessous</span>-->
        </div>
        <div class="c">
          N° de commande
          <span>{{ n.commande_id }} - {{n.price | formatNumberdecimal}}€</span>
        </div>
      </div>

      <el-progress :color="colors" :percentage="n.status_indicator" :show-text="false"></el-progress>
      
      <div class="boutique_info">
        <div v-if="n.plan" class="e retrait">Planifiée pour : <span class="capitalize">{{ n.plan.dayFormat }} {{ n.plan.hour }} </span></div>
        <div v-if="n.livraison.type === 'retrait'" class="e retrait">Methode de livraison: <span class="capitalize">{{ n.livraison.type }} {{ n.livraison.day }} {{ n.livraison.hour }}</span></div>
        <div class="e">{{ n.b_name }}</div>
        <div class="e">{{ n.b_street_number }} {{ n.b_route }}, {{ n.b_postal_code }} {{ n.b_locality }}</div>
        <div class="e"><a :href="'tel:' + n.b_international_phone_number">{{ n.b_national_phone_number }}</a></div>
      </div>
      
      <div v-if="n.livraison.type === 'livraison'" class="boutique_info">
        <div class="e bold">Livraison</div>
        <div class="e">{{ n.livraison.adresse.adresse.streetNumber }} {{ n.livraison.adresse.adresse.streetName }}, {{ n.livraison.adresse.adresse.zipcode }} {{ n.livraison.adresse.adresse.city }}</div>
        <div class="e"><a :href="'tel:' + n.user_phone">{{ n.user_phone }}</a></div>
      </div>
      
      
      <div class="articles">
        <div class="e" v-for="a in n.articles.articles" :key="a.id">
          <div class="t">
            {{ a.nb }}x {{ a.data.name }}
            <span class="price"></span>
          </div>
          <span v-for="(o, k) in a.options.options" :key="k">{{ o.name }}</span>
        </div>
      </div>
      
      
    </div>
    
    
  </div>
</template>

<script>
export default {
  name: "commandes",
  data() {
    return {
      commandes: {},
      colors: [
        {color: '#f56c6c', percentage: 50},
        {color: '#e6a23c', percentage: 80},
        {color: '#5cb87a', percentage: 100}
      ]
    }
  },
  mounted() {
    this.$loader = true
    this.$http.get('/user/commandes_get').then((commandes) => {
      this.commandes = commandes.data
      this.$loader = false
    })
  }
}
</script>

<style scoped>
  .commande { background: #fff; padding: 16px; margin-top: 6px; }
  .commande .top { display: flex; justify-content: space-between; margin-bottom: 10px; }
  .commande .top .c.etat { font-size: 24px; }
  .commande .top .c { display: flex; flex-direction: column; justify-content: center; font-size: 18px; font-weight: 200; }
  .commande .top .c span { font-weight: 500; font-size: 16px }
  
  .commande .boutique_info { font-size: 16px; font-weight: 400; margin-top: 16px; border-bottom: 1px solid #eee; padding-bottom: 16px; }
  .commande .boutique_info .e.retrait { font-weight: 500; margin-bottom: 10px; }
  .commande .boutique_info .e.retrait span { margin-left: 6px; background: #f5f5f5; font-size: 14px; padding: 2px 4px; border-radius: 4px }
  .commande .boutique_info .e { height: 26px; display: flex; align-items: center }
  .commande .boutique_info .e.bold { font-weight: bold; }
  
  .commande .articles { font-size: 16px; line-height: 18px; font-weight: 400; margin-top: 16px; }
  .commande .articles .e { display: flex; flex-direction: column; margin-bottom: 16px; }
  .commande .articles .e:last-child { margin-bottom: 0; }
  .commande .articles .e span { color: var(--black-sub); padding-top: 4px; padding-left: 8px; }

</style>